// components/PriceEstimation.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ResponsiveContainer, ComposedChart, Bar, XAxis, Tooltip, ReferenceLine } from 'recharts';
import { Card } from 'react-bootstrap';
import './PriceEstimation.css';

function PriceEstimation({ estimatedPrice }) {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    axios.get('/api/price_distribution')
      .then(response => {
        const binEdges = response.data.bin_edges;
        const counts = response.data.counts;
        // Prepare data for the chart
        const data = counts.map((count, index) => ({
          priceMid: (binEdges[index] + binEdges[index + 1]) / 2,
          count: count,
        }));
        setChartData(data);
      })
      .catch(error => {
        console.error('Error fetching price distribution:', error);
      });
  }, []);

  return (
    <section className="price-estimation-section">
      <Card>
        <Card.Body>
          <h2>Estimated Price</h2>
          <Card className="my-3">
            <Card.Body>
              <h1 className="text-success">
                💰 ${estimatedPrice.predicted_price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} JMD
              </h1>
            </Card.Body>
          </Card>
          <p>Your estimated home value is higher than <strong>{estimatedPrice.percentile.toFixed(1)}%</strong> of residential properties in Jamaica.</p>

          <h3>Jamaican Home Prices</h3>
          <ResponsiveContainer width="100%" height={300}>
            <ComposedChart data={chartData}>
              <XAxis dataKey="priceMid" type="number" domain={['dataMin', 'dataMax']} tickFormatter={(value) => `$${(value / 1e6).toFixed(1)}M`} />
              <Tooltip formatter={(value) => value} labelFormatter={(label) => `$${(label / 1e6).toFixed(1)}M`} />
              <Bar dataKey="count" fill="green" />
              {/* Add a vertical line for the estimated price */}
              <ReferenceLine x={estimatedPrice.predicted_price} stroke="red" label={{ position: 'insideTop', value: 'Yours', fill: 'red', offset: -3 }}  />
            </ComposedChart>
          </ResponsiveContainer>
        </Card.Body>
      </Card>
    </section>
  );
}

export default PriceEstimation;
