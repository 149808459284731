// App.js
import React, { useState } from 'react';
import MapComponent from './components/MapComponent';
import PropertyForm from './components/PropertyForm';
import PriceEstimation from './components/PriceEstimation';
import './App.css';
import { Container } from 'react-bootstrap';
import 'leaflet/dist/leaflet.css';

function App() {
  const [location, setLocation] = useState(null);
  const [propertyDetails, setPropertyDetails] = useState(null);

  return (
    <div className="App">
      <header className="bg-light py-3 mb-4">
        <Container>
          <h1>🏠 Yaad Price</h1>
          <p>Get a near-instant estimate of your home value with the latest real estate data.</p>
        </Container>
      </header>
      <Container>
        <main>
          <MapComponent setLocation={setLocation} />
          {location && (
            <PropertyForm
              location={location}
              setPropertyDetails={setPropertyDetails}
            />
          )}
          {propertyDetails && (
            <PriceEstimation
              estimatedPrice={propertyDetails}
            />
          )}
        </main>
      </Container>
    </div>
  );
}

export default App;
