// components/MapComponent.js
import React, { useState } from 'react';
import { MapContainer, TileLayer, useMapEvents, Marker } from 'react-leaflet';
import { Card } from 'react-bootstrap';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css'; // Import Leaflet CSS
import './MapComponent.css';

// Import marker images
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

// Fix for marker icon issue
L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon2x,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

function MapComponent({ setLocation }) {
  const [markerPosition, setMarkerPosition] = useState(null);

  const MapEvents = () => {
    useMapEvents({
      click(e) {
        const { lat, lng } = e.latlng;
        setLocation({ latitude: lat, longitude: lng });
        setMarkerPosition([lat, lng]);
      },
    });
    return null;
  };

  return (
    <section className="map-section mb-4">
      <Card>
        <Card.Body>
          <h2>Select Property Location</h2>
          <p>Click on the map to select the property location.</p>
          <MapContainer center={[18.1096, -77.2975]} zoom={8} scrollWheelZoom={true} className="map-container">
            <TileLayer
              attribution='&copy; OpenStreetMap contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <MapEvents />
            {markerPosition && <Marker position={markerPosition} />}
          </MapContainer>
        </Card.Body>
      </Card>
    </section>
  );
}

export default MapComponent;
