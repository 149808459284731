// components/PropertyForm.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button, Card, Row, Col } from 'react-bootstrap';
import './PropertyForm.css';



function PropertyForm({ location, setPropertyDetails }) {
  const [locationDetails, setLocationDetails] = useState({});
  const [propertyData, setPropertyData] = useState({
    property_type: 'house',
    bedrooms: 3,
    bathrooms: 2,
    size: '',
    size_lot: '',
    assumeAvgSize: true,
    assumeAvgSizeLot: true,
    listing_date: new Date().toISOString().split('T')[0],
    gated_community: false,
    swimming_pool: false,
    beachfront: false,
    garage: false,
    repairs_required: false,
    assumeAvgAesthetic: true,
    luxury_level: null,
    images: [],
  });

  useEffect(() => {
    // Fetch location details from API
    axios.post('/api/get_location_details', {
      latitude: location.latitude,
      longitude: location.longitude,
    })
    .then(response => {
      setLocationDetails(response.data);
    })
    .catch(error => {
      console.error('Error fetching location details:', error);
    });
  }, [location]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setPropertyData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleImageUpload = (e) => {
    setPropertyData(prevData => ({
      ...prevData,
      images: Array.from(e.target.files),
    }));
  };

  const handleGetAIQualityScore = () => {
    const formData = new FormData();
    propertyData.images.forEach(file => {
      formData.append('files', file);
    });

    axios.post('/api/get_ai_quality_score', formData)
      .then(response => {
        setPropertyData(prevData => ({
          ...prevData,
          luxury_level: response.data.luxury_level,
        }));
      })
      .catch(error => {
        console.error('Error getting AI quality score:', error);
      });
  };

  const handleEstimatePrice = () => {
    // Calculate dayIndex
    const startDate = new Date('2020-01-01');
    const listingDate = new Date(propertyData.listing_date);
    const dayIndex = Math.floor((listingDate - startDate) / (1000 * 60 * 60 * 24));

    // Prepare data for API
    const data = {
      latitude: location.latitude,
      longitude: location.longitude,
      property_type: propertyData.property_type,
      bedrooms: propertyData.bedrooms,
      bathrooms: propertyData.bathrooms,
      size: propertyData.assumeAvgSize ? null : parseFloat(propertyData.size),
      size_lot: propertyData.assumeAvgSizeLot || propertyData.property_type === 'apartment' ? null : parseFloat(propertyData.size_lot),
      dayIndex: dayIndex,
      gated_community: propertyData.gated_community ? 'yes' : 'no',
      swimming_pool: propertyData.swimming_pool ? 'yes' : 'no',
      beachfront: propertyData.beachfront ? 'yes' : 'no',
      garage: propertyData.garage ? 'yes' : 'no',
      repairs_required: propertyData.repairs_required ? 'yes' : 'no',
      luxury_level: propertyData.luxury_level,
    };

    axios.post('/api/estimate_price', data)
      .then(response => {
        setPropertyDetails(response.data);
      })
      .catch(error => {
        console.error('Error estimating price:', error);
      });
  };

  return (
    <section className="property-form-section">
      <Card className="mb-3">
        <Card.Body>
          <h2>Property Details</h2>
          <Card className="mb-3">
            <Card.Body>
              <h4>Location Details</h4>
              <Row>
                <Col md={4}><strong>Community:</strong> {locationDetails.community_name}</Col>
                <Col md={4}><strong>Parish:</strong> {locationDetails.parish_name}</Col>
                <Col md={4}><strong>Capital City:</strong> {locationDetails.capital_city === 'yes' ? 'Yes' : 'No'}</Col>
              </Row>
            </Card.Body>
          </Card>
          <Form>
            {/* Property Type */}
            <Form.Group controlId="propertyType">
              <Form.Label>Property Type</Form.Label>
              <Form.Control as="select" name="property_type" value={propertyData.property_type} onChange={handleInputChange}>
                <option value="house">House</option>
                <option value="townhouse">Townhouse</option>
                <option value="apartment">Apartment</option>
              </Form.Control>
            </Form.Group>

            {/* Bedrooms and Bathrooms */}
            <Row>
              <Col md={6}>
                <Form.Group controlId="bedrooms">
                  <Form.Label>Number of Bedrooms</Form.Label>
                  <Form.Control type="number" name="bedrooms" min="0" value={propertyData.bedrooms} onChange={handleInputChange} />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="bathrooms">
                  <Form.Label>Number of Bathrooms</Form.Label>
                  <Form.Control type="number" name="bathrooms" min="0" value={propertyData.bathrooms} onChange={handleInputChange} />
                </Form.Group>
              </Col>
            </Row>

            {/* Size */}
            <Form.Group controlId="assumeAvgSize">
              <Form.Check type="checkbox" label="Assume average size" name="assumeAvgSize" checked={propertyData.assumeAvgSize} onChange={handleInputChange} />
            </Form.Group>
            {!propertyData.assumeAvgSize && (
              <Form.Group controlId="size">
                <Form.Label>Size (sqft)</Form.Label>
                <Form.Control type="number" name="size" min="0" value={propertyData.size} onChange={handleInputChange} />
              </Form.Group>
            )}

            {/* Lot Size */}
            {propertyData.property_type !== 'apartment' && (
              <>
                <Form.Group controlId="assumeAvgSizeLot">
                  <Form.Check type="checkbox" label="Assume average lot size" name="assumeAvgSizeLot" checked={propertyData.assumeAvgSizeLot} onChange={handleInputChange} />
                </Form.Group>
                {!propertyData.assumeAvgSizeLot && (
                  <Form.Group controlId="size_lot">
                    <Form.Label>Lot Size (sqft)</Form.Label>
                    <Form.Control type="number" name="size_lot" min="0" value={propertyData.size_lot} onChange={handleInputChange} />
                  </Form.Group>
                )}
              </>
            )}


            {/* Additional Features */}
            <Form.Group controlId="additionalFeatures">
              <Form.Check type="checkbox" label="Gated Community" name="gated_community" checked={propertyData.gated_community} onChange={handleInputChange} />
              <Form.Check type="checkbox" label="Swimming Pool" name="swimming_pool" checked={propertyData.swimming_pool} onChange={handleInputChange} />
              <Form.Check type="checkbox" label="Beachfront" name="beachfront" checked={propertyData.beachfront} onChange={handleInputChange} />
              <Form.Check type="checkbox" label="Garage" name="garage" checked={propertyData.garage} onChange={handleInputChange} />
              <Form.Check type="checkbox" label="Repairs Required" name="repairs_required" checked={propertyData.repairs_required} onChange={handleInputChange} />
            </Form.Group>

            {/* AI Visual Quality Score */}
            <h3>Property Images</h3>
            <Form.Group controlId="assumeAvgAesthetic">
              <Form.Check type="checkbox" label="Assume average aesthetic quality" name="assumeAvgAesthetic" checked={propertyData.assumeAvgAesthetic} onChange={handleInputChange} />
            </Form.Group>
            {!propertyData.assumeAvgAesthetic && (
              <Form.Group controlId="images">
                <Form.Label>Upload photos of the property (maximum 5 images, 2MB per image)</Form.Label>
                <Form.Control
                  type="file"
                  multiple
                  accept="image/*"
                  onChange={handleImageUpload}
                />
                <Button variant="primary" onClick={handleGetAIQualityScore} className="mt-2">Get AI Visual Quality Score</Button>
              </Form.Group>
            )}
            {propertyData.luxury_level && (
              <Card className="mt-3">
                <Card.Body>
                  <h4>AI Visual Quality Score</h4>
                  <p>{propertyData.luxury_level}</p>
                </Card.Body>
              </Card>
            )}

            <Button variant="success" onClick={handleEstimatePrice} className="mt-3">Estimate Price</Button>
          </Form>
        </Card.Body>
      </Card>
    </section>
  );
}

export default PropertyForm;